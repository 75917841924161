import { graphql, useStaticQuery } from "gatsby";

export const useFaqs = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        limit: 1000
        filter: { frontmatter: { templateKey: { eq: "faq" } } }
      ) {
        group(field: frontmatter___category) {
          fieldValue
          totalCount
          edges {
            node {
              frontmatter {
                answer
                category
                question
                date
              }
              fields {
                slug
                frontmattermd {
                  answer {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return data.allMarkdownRemark.group.map(
    ({ edges, fieldValue, totalCount }) => {
      return {
        category: fieldValue,
        count: totalCount,
        faqs: edges.map(({ node }) => ({
          ...node.frontmatter,
          slug: node.fields.slug,
          answerText: node.frontmatter.answer,
          answer: node.fields.frontmattermd.answer.html
        }))
      };
    }
  );
};
