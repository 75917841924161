import React from "react";
import PropTypes from "prop-types";
import { graphql, withPrefix } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Slide from "react-reveal/Slide";
import Zoom from "react-reveal/Zoom";
import { MdHighQuality, MdLocationCity, MdStars } from "react-icons/md";
import {
  FaLeaf,
  FaUserClock,
  FaChartLine,
  FaLaptopMedical
} from "react-icons/fa";
import { IoIosFlask } from "react-icons/io";
import { FiThumbsUp } from "react-icons/fi";
import { GiMedicines } from "react-icons/gi";

import Layout from "../components/Layout";
import { H1 } from "../components/Typography";
import Underline from "../components/Underline";
import Button from "../components/Button";
import Faq from "../components/Faq";
import Product from "../components/Product";
import BlogRoll from "../components/BlogRoll";
import { useFaqs } from "../hooks/useFaqs";
import { useProducts } from "../hooks/useProducts";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import HeroSlider from "../components/hero-slider";
import useSiteMetadata from "../components/SiteMetadata";
import energyIcon from "../../static/images/energy.svg";
import medicineIcon from "../../static/images/medicine.svg";
import nutritionIcon from "../../static/images/nutrition.svg";

const HOME_FAQ_CATEGORY = "basics";
const BENEFITS_ICONS = [energyIcon, nutritionIcon, medicineIcon];
const PROMISES_ICONS = [
  <FaLeaf />,
  <MdStars />,
  <MdHighQuality />,
  <FaUserClock />,
  <IoIosFlask />,
  <MdLocationCity />
];
const WHY_ICONS = [
  <FaLeaf size={28} />,
  <IoIosFlask size={28} />,
  <FiThumbsUp size={28} />,
  <FaLaptopMedical size={28} />,
  <GiMedicines size={28} />,
  <FaChartLine size={28} />
];

export const IndexPageTemplate = ({ content }) => {
  const { siteUrl } = useSiteMetadata();
  const allProducts = useProducts();
  const allFaqs = useFaqs();
  const faqs = allFaqs.find(({ category }) => category === HOME_FAQ_CATEGORY)
    .faqs;
  const products = allProducts.slice(0, 3);
  return (
    <>
      <GatsbySeo
        title={content.seo.title}
        description={content.seo.description}
        openGraph={{
          url: siteUrl,
          title: content.seo.title,
          description: content.seo.description,
          images: [{ url: `${siteUrl}${content.seo.image.publicURL}` }],
          site_name: "MycoForest"
        }}
      />
      <section className="hero">
        <div className="hero-container">
          <div className="container">
            <HeroSlider items={content.homeHero} />
          </div>
        </div>
        <div className="waves">
          <svg
            x="0px"
            y="0px"
            viewBox="0 668.578 1440 189.422"
            enableBackground="new 0 668.578 1440 189.422"
            xmlSpace="preserve"
          >
            <filter
              id="b"
              filterUnits="objectBoundingBox"
              height="105.4%"
              y="-2.8%"
              x="-1.6%"
              width="103.2%"
            >
              <feOffset result="shadowOffsetOuter1" in="SourceAlpha" dy={-1} />
              <feGaussianBlur
                stdDeviation={7.5}
                result="shadowBlurOuter1"
                in="shadowOffsetOuter1"
              />
              <feColorMatrix
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.540455638 0"
                in="shadowBlurOuter1"
              />
            </filter>
            <filter
              id="a"
              filterUnits="objectBoundingBox"
              height="105.4%"
              y="-2.8%"
              x="-1.6%"
              width="103.2%"
            >
              <feOffset result="shadowOffsetOuter1" in="SourceAlpha" dy={-1} />
              <feGaussianBlur
                stdDeviation={7.5}
                result="shadowBlurOuter1"
                in="shadowOffsetOuter1"
              />
              <feColorMatrix
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.540455638 0"
                in="shadowBlurOuter1"
              />
            </filter>
            <g filter="url(#a)" transform="translate(-3 -3)">
              <path d="M3.833 838.877c50.041 11.333 94.628 12.665 135.584-3.475 232.018-91.43 214.397-146.712 349.498-133.533 135.101 13.18 338.31 211.479 551.325 90.289 165.703-94.271 318.985 34.813 405.628-12.184l-1.127-201.58V0L2 3.458l1.833 835.419z" />
            </g>
            <path
              fill="#FF8100"
              d="M3.833 838.877c50.041 11.333 94.628 12.665 135.584-3.475 232.018-91.43 214.397-146.712 349.498-133.533 135.101 13.18 338.31 211.479 551.325 90.289 165.703-94.271 318.985 34.813 405.628-12.184l-1.127-201.58V0L2 3.458l1.833 835.419z"
              transform="translate(-3 -3)"
            />
            <g>
              <g filter="url(#b)" transform="translate(-3 -3)">
                <path d="M3 781.5c70.153 23.608 143.175 21.459 219.064-6.446 273.494-100.569 280.548-133.865 520.788-53.483s338.252 191.473 551.231 70.282c56.591-32.2 106.863-48.961 150.819-50.281L1446.966 0 3.088 2.36 3 781.5z" />
              </g>
              <path
                fill="#F5F0EB"
                d="M3 781.5c70.153 23.608 143.175 21.459 219.064-6.446 273.494-100.569 280.548-133.865 520.788-53.483s338.252 191.473 551.231 70.282c56.591-32.2 106.863-48.961 150.819-50.281L1446.966 0 3.088 2.36 3 781.5z"
                transform="translate(-3 -3)"
              />
            </g>
          </svg>
        </div>
      </section>
      <section className="benefits">
        <div className="container">
          <H1 alignCenter as="h2">
            {content.homeBenefits.title}
          </H1>
          <Underline />
          <p className="subtitle">{content.homeBenefits.subtitle}</p>
          <div className="items">
            {content.homeBenefits.items.map((item, index) => (
              <a href={item.href} key={item.title}>
                <div className="image-container">
                  <img src={BENEFITS_ICONS[index]} alt={item.title} />
                </div>
                <div className="content">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>
      <section className="promises">
        <div className="container">
          <H1 alignCenter as="h2">
            {content.promises.title}
          </H1>
          <Underline />
          <div className="content">
            <div className="items">
              {content.promises.items.slice(0, 3).map((item, index) => (
                <div key={index} className="item">
                  <Zoom>
                    <div className="icon-container">
                      {PROMISES_ICONS[index]}
                    </div>
                  </Zoom>
                  <div className="info">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="image-container">
              <PreviewCompatibleImage
                imageInfo={content.promises.image}
                alt="promises"
              />
            </div>
            <div className="items right">
              {content.promises.items
                .slice(3, content.promises.items.length)
                .map((item, index) => (
                  <div key={index} className="item">
                    <Zoom>
                      <div className="icon-container">
                        {PROMISES_ICONS[index + 3]}
                      </div>
                    </Zoom>
                    <div className="info">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <section className="why">
        <div className="container">
          <div className="content">
            <div className="items">
              <H1 alignCenter as="h2">
                {content.why.title}
              </H1>
              <Underline />
              {content.why.items.map(({ item }, index) => (
                <div className="item" key={index}>
                  <Zoom>
                    <div className="icon-container">{WHY_ICONS[index]}</div>
                  </Zoom>
                  <p>{item}</p>
                </div>
              ))}
            </div>
            <div className="image-container">
              <PreviewCompatibleImage imageInfo={content.why.image} alt="why" />
            </div>
          </div>
        </div>
      </section>
      <section className="products">
        <div className="container">
          <H1 alignCenter as="h2">
            Our Products
          </H1>
          <Underline />
          <ul>
            {products.map((product, index) => (
              <Product product={product} index={index} key={index} />
            ))}
          </ul>
          <div className="btn-container">
            <Button href="/shop" isInternal isSolid={false}>
              View All Products
            </Button>
          </div>
        </div>
      </section>
      <section className="faqs">
        <div className="container">
          <H1 alignCenter as="h2">
            Frequently Asked Questions
          </H1>
          <Underline />
          <ul>
            {faqs.map((faq, index) => (
              <Slide bottom key={index}>
                <Faq faq={faq} key={index} />
              </Slide>
            ))}
          </ul>
          <div className="btn-container">
            <Button href="/frequently-asked-questions" isInternal>
              View All FAQ's
            </Button>
          </div>
        </div>
      </section>
      <section className="blog">
        <div className="container">
          <H1 alignCenter as="h2">
            Our Blog
          </H1>
          <Underline />
          <BlogRoll isFeatured limit={3} />
          <div className="btn-container">
            <Button href="/blog" isInternal>
              View All Posts
            </Button>
          </div>
        </div>
      </section>
      <style jsx>{`
        section {
          padding: 32px;
        }

        section.hero {
          padding: 0;
        }

        section.blog,
        section.products,
        section.benefits,
        section.promises,
        section.faqs {
          padding-bottom: 64px;
        }

        section.faqs {
          padding-top: 64px;
          padding-bottom: 64px;
          background-image: linear-gradient(180deg, #f5f0eb, #fff);
        }

        section.why {
          padding: 32px 0;
        }

        ul {
          list-style: none;
          margin: 0 auto;
          padding: 0;
        }

        .hero-container {
          background: #f5f0eb;
        }

        .container {
          max-width: 1278px;
          margin: 0 auto;
        }

        section.hero .container {
          padding: 32px 0;
          padding-bottom: 0;
        }

        section.hero .waves {
          position: relative;
          top: -4px;
        }

        section.hero :global(.slick-list) {
          position: relative;
          z-index: 10;
        }

        .why .container {
          max-width: 100%;
        }

        .btn-container {
          display: flex;
          justify-content: center;
        }

        .hero .container {
          padding: 32px 0;
          padding-bottom: 150px;
        }

        .hero .waves {
          position: relative;
          top: 4px;
        }

        .subtitle {
          font-size: 24px;
          text-align: center;
          font-family: "Lato", sans-serif;
          font-weight: 300;
          margin: 0;
          margin-bottom: 48px;
        }

        .benefits .items {
          display: flex;
          max-width: 1080px;
          width: 100%;
          justify-content: space-between;
          margin: 0 auto;
        }

        .benefits .items a {
          display: block;
          width: calc(33.33% - 16px);
          margin: 0 auto;
          text-align: center;
          margin: 8px;
          color: inherit;
        }

        .benefits .items .image-container {
          width: 120px;
          height: 120px;
          margin: 0 auto;
          margin-bottom: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f5f0eb;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        }

        .benefits .items .image-container img {
          width: 55px;
          height: 65px;
        }

        .benefits .items h3 {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 20px;
          margin-bottom: 12px;
          font-family: "Open Sans", sans-serif;
        }
        .benefits .items p {
          font-weight: 300;
          font-size: 20px;
          max-width: 240px;
          margin: 0 auto;
          line-height: 1.5;
          font-family: "Lato", sans-serif;
        }

        .promises .content {
          display: flex;
        }

        .promises .content > * {
          flex: 1;
          width: 100%;
        }

        .promises .items {
          text-align: left;
        }

        .promises .items.right {
          text-align: right;
        }

        .promises .item {
          margin-bottom: 40px;
          display: flex;
        }

        .promises .item:last-child {
          margin-bottom: 0;
        }

        .promises .items.right .item {
          flex-direction: row-reverse;
        }

        .promises .icon-container {
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fe8100;
          background: #f5f0eb;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          border-radius: 50%;
        }

        .promises .icon-container :global(svg) {
          width: 32px;
          height: 32px;
        }

        .promises .items .info {
          margin-left: 16px;
          flex: 1;
        }

        .promises .items.right .info {
          margin-left: 0;
          margin-right: 16px;
        }

        .promises .items h3 {
          font-weight: 700;
          font-size: 20px;
          margin: 0;
          margin-bottom: 8px;
          font-family: "Open Sans", sans-serif;
        }
        .promises .items p,
        .why .items p {
          font-weight: 300;
          font-size: 18px;
          line-height: 1.5;
          margin: 0;
          font-family: "Lato", sans-serif;
        }

        .why .content {
          display: flex;
          align-items: center;
        }

        .why .content > * {
          flex: 1;
        }

        .why :global(h2) {
          text-align: left;
        }

        .why :global(.line) {
          margin-left: 0;
          margin-right: 0;
        }

        .why .content .items {
          padding: 0 32px;
          margin-left: 48px;
        }

        .why .content .items .item {
          display: flex;
          align-items: center;
        }

        .why .items p {
          max-width: 600px;
          font-size: 22px;
          margin: 10px 24px;
        }

        .why .items .icon-container {
          color: #fe8100;
        }

        .why .header .image-container {
          display: none;
        }

        .faqs ul {
          max-width: 900px;
          margin-bottom: 48px;
        }

        .products ul {
          display: flex;
          justify-content: center;
          margin-bottom: 48px;
        }

        @media (max-width: 1180px) {
          .products ul {
            justify-content: unset;
            overflow-x: auto;
          }

          .products ul :global(li) {
            min-width: 420px;
          }

          section.products {
            padding-right: 0;
          }
        }

        @media (max-width: 1080px) {
          .promises .image-container {
            display: none;
          }

          .promises .items.right {
            margin-left: 32px;
          }

          .why .content {
            display: block;
          }

          .why .image-container {
            display: none;
          }

          .why .content .items {
            padding: 0 32px;
            margin-left: 16px;
          }
        }

        @media (max-width: 900px) {
          section.benefits {
            padding-right: 0;
            padding-left: 0;
          }

          section.benefits .subtitle {
            padding-right: 20px;
            padding-left: 20px;
          }

          section.benefits :global(h2) {
            padding-right: 20px;
            padding-left: 20px;
          }

          .benefits .items {
            overflow-x: auto;
            max-width: unset;
            width: auto;
            justify-content: unset;
          }

          .benefits .items a {
            min-width: 245px;
          }

          .benefits :global(.line) {
            margin-bottom: 24px;
          }

          .promises .items.right {
            margin-left: 0;
            text-align: left;
          }

          .promises .content {
            flex-direction: column;
          }

          .promises .items.right .item {
            flex-direction: row;
          }

          .promises .items.right .info {
            margin-left: 16px;
            margin-right: 0;
          }
          .promises .item,
          .promises .item:last-child {
            margin-bottom: 32px;
          }

          .why .content .items {
            margin-left: 0;
          }
        }

        @media (max-width: 758px) {
          .subtitle {
            font-size: 20px;
          }
          .benefits .items p {
            font-size: 18px;
          }

          section.blog,
          section.products,
          section.benefits,
          section.promises,
          section.faqs {
            padding-bottom: 24px;
          }
        }

        @media (max-width: 600px) {
          section.hero .waves {
            position: relative;
          }
          .products ul {
            justify-content: center;
            overflow-x: unset;
            flex-wrap: wrap;
          }

          .products ul :global(li) {
            min-width: unset;
            max-width: 420px;
            width: 100%;
            margin: 0;
            margin-bottom: 32px;
          }

          .products ul :global(li):last-child {
            margin-bottom: 0;
          }

          section.products {
            padding-right: 32px;
          }

          .promises .icon-container {
            width: 50px;
            height: 50px;
          }

          .promises .icon-container :global(svg) {
            width: 28px;
            height: 28px;
          }

          .promises .items h3 {
            font-size: 18px;
          }

          .promises .items p,
          .why .items p {
            font-size: 16px;
          }
        }

        @media (max-width: 580px) {
          section {
            padding: 32px 20px;
          }

          section.products {
            padding-right: 20px;
          }

          .why .content .items {
            padding: 0 20px;
          }
          .subtitle {
            font-size: 16px;
          }
        }
      `}</style>
    </>
  );
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate content={frontmatter} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            publicURL
          }
        }
        homeHero {
          image {
            publicURL
            extension
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          items {
            item
          }
        }
        homeBenefits {
          title
          subtitle
          items {
            title
            description
            image {
              publicURL
              extension
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        promises {
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          items {
            description
            title
          }
        }
        why {
          title
          items {
            item
          }
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
