import React from "react";
import { Link } from "gatsby";

import { PriceInfo } from "./Shop";
import Button from "./Button";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { ProductJsonLd } from "./JsonLd";

export default function Product({ product, index }) {
  return (
    <>
      <ProductJsonLd product={product} />
      <li key={index}>
        <div className="image-container">
          <Link to={product.slug}>
            <PreviewCompatibleImage
              imageInfo={product.images[0]}
              alt={product.name}
            />
          </Link>
        </div>
        <h3>
          <Link to={product.slug}>{product.name}</Link>
        </h3>
        <div className="info">
          <PriceInfo product={product} quantity={1} />
          <Button isInternal href={`${product.slug}/buy`}>
            Buy Now
          </Button>
        </div>
        <style jsx>{`
          li {
            transition: all 0.25s;
            min-height: 64px;
            margin: 0;
            margin-right: 20px;
            font-family: "Lato", sans-serif;
            border: 1px solid #f7fafc;
            border-radius: 8px;
            transition: all 0.2s;
            background: #fff;
            width: calc(33.33% - 20px);
          }

          li:hover {
            transform: scale(1.05);
          }

          li:last-child {
            margin-right: 0;
          }

          li h3 {
            padding: 16px 4px;
            padding-bottom: 0;
            margin: 0;
          }

          li h3 :global(a) {
            color: #000;
          }

          li .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px 4px;
            padding-top: 0;
          }

          li :global(.btn-link) {
            width: 150px;
          }

          @media (max-width: 600px) {
            li {
              margin: 16px;
            }
            li {
              border: none;
            }

            li h3 {
              padding: 16px 0;
              padding-bottom: 8px;
              font-size: 18px;
            }
            li .info {
              padding: 0;
              padding-bottom: 8px;
            }
          }

          @media (max-width: 460px) {
            li .info {
              align-items: baseline;
            }

            li h3 {
              padding-bottom: 0;
            }

            li :global(.btn-link) {
              margin-top: 12px;
              font-size: 14px;
              width: 130px;
            }
          }

          @media (max-width: 420px) {
            li {
              width: 100%;
            }
          }
        `}</style>
      </li>
    </>
  );
}
