import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";

import Post from "./post";

class BlogRoll extends React.Component {
  render() {
    const { data, isFeatured, limit } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className="posts">
        {posts &&
          posts
            .filter(({ node: post }) =>
              isFeatured ? post.frontmatter.featuredpost : true
            )
            .map(({ node: post }) => <Post key={post.id} post={post} />)
            .slice(0, limit)}
        <style jsx>{`
          .posts {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }
        `}</style>
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default ({ isFeatured, limit = 1000 }) => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                seo {
                  title
                  description
                  authorName
                  publisherName
                  publishedLogo {
                    publicURL
                  }
                  image {
                    publicURL
                  }
                }
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                dateIso: date
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <BlogRoll
        isFeatured={isFeatured}
        data={data}
        count={count}
        limit={limit}
      />
    )}
  />
);
