import React, { useState } from "react";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { JsonLd } from "gatsby-plugin-next-seo";
import md from "md.js";

import useSiteMetadata from "./SiteMetadata";
import { HTMLContent } from "./Content";

export default function Faq({ faq, isHtml = true }) {
  const { siteUrl } = useSiteMetadata();
  const [isExpanded, setIsExpanded] = useState(false);
  const json = {
    "@context": "https://schema.org",
    "@type": "Question",
    name: faq.question,
    text: faq.question,
    dateCreated: faq.date ? new Date(faq.date).toISOString() : undefined,
    acceptedAnswer: {
      "@type": "Answer",
      text: faq.answerText,
      url: `${siteUrl}${faq.slug}`,
      dateCreated: faq.date ? new Date(faq.date).toISOString() : undefined,
    },
  };
  return (
    <>
      <JsonLd json={json} />
      <li key={faq.question}>
        <h3 onClick={() => setIsExpanded((isExpanded) => !isExpanded)}>
          {faq.question}
          <span className="text-gray-900">
            {isExpanded ? (
              <MdExpandLess size={28} />
            ) : (
              <MdExpandMore size={29} />
            )}
          </span>
        </h3>
        {isHtml ? (
          <div
            className="markdown answer"
            dangerouslySetInnerHTML={{ __html: faq.answer }}
          ></div>
        ) : (
          <HTMLContent className="markdown answer" content={md(faq.answer)} />
        )}
        <style jsx>{`
          li {
            transition: all 0.25s;
            min-height: 64px;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
            margin: 16px 0;
            margin-top: 8px;
            font-family: "Lato", sans-serif;
            border: 1px solid #f7fafc;
            border-radius: 8px;
            padding: 0 16px;
            transition: all 0.2s;
            background: #fff;
          }

          h3 {
            font-family: "Open Sans", sans-serif;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: ${isExpanded ? 0 : 12}px;
            cursor: pointer;
            user-select: none;
          }

          li :global(.answer) {
            height: ${isExpanded ? "auto" : 0};
            opacity: ${isExpanded ? 1 : 0};
            padding-bottom: ${isExpanded ? 12 : 0}px;
            position: relative;
            z-index: ${isExpanded ? 1 : -1};
          }
        `}</style>
      </li>
    </>
  );
}
