import React from "react";
import Slider from "react-slick";
import clsx from "clsx";

import PreviewCompatibleImage from "./PreviewCompatibleImage";
import Button from "./Button";
import leftArrow from "../../static/images/left-arrow.png";
import rightArrow from "../../static/images/right-arrow.png";

const SLIDER_SETTINGS = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

export default ({ items }) => {
  return (
    <Slider {...SLIDER_SETTINGS}>
      {items.map((item, index) => (
        <Slide item={item} index={index} key={index} />
      ))}
    </Slider>
  );
  // return <Slide item={items[0]} index={0} />;
};

function Slide({ item, index }) {
  const isOdd = index % 2 !== 0;
  const isGif = item.image.extension === "gif";
  return (
    <div className="slide">
      <div className={clsx("content", isOdd && "is-odd")}>
        <h1 className={clsx(isOdd && "is-odd")}>{item.title}</h1>
        {item.items && (
          <ul>
            {item.items.map(({ item }) => (
              <li key={item}>
                <span className="icon"></span>
                {item}
              </li>
            ))}
          </ul>
        )}
        <div className={clsx("btns", isOdd && "is-odd")}>
          <Button isInternal href="/shop">
            Shop Now
          </Button>
          <Button isInternal href="/contact" isInverted>
            Know More
          </Button>
        </div>
      </div>
      <div
        className={clsx(
          "image-container",
          isOdd && "is-odd",
          (isGif && index !== 2) || index === 1 && "is-gif"
        )}
      >
        {!isGif ? (
          <PreviewCompatibleImage imageInfo={item.image} alt={item.title} />
        ) : (
          <div className="gatsby-image-wrapper">
            <img src={item.image.publicURL} alt={item.title} />
          </div>
        )}
      </div>
      <style jsx>{`
        .slide {
          display: flex;
          max-width: 1024px;
          margin: 0 auto;
          align-items: center;
          position: relative;
          z-index: 10;
        }

        .slide > * {
          flex: 1;
          order: 2;
        }

        .slide .is-odd {
          position: relative;
          left: -32px;
        }

        .slide .image-container.is-odd {
          order: 1;
        }

        h1 {
          font-size: 47px;
          text-align: left;
          margin: 0;
          margin-bottom: 12px;
          font-family: "Open Sans", sans-serif;
          font-weight: inherit;
        }

        h1.is-odd {
          text-align: right;
        }

        ul {
          display: flex;
          margin: 0;
          padding: 0;
          list-style: none;
          font-family: "Lato", sans-serif;
          font-size: 18px;
          font-weight: 300;
          margin-top: 24px;
        }

        ul li:not(:last-child) {
          margin-right: 16px;
        }

        ul li {
          display: flex;
          align-items: center;
        }

        li .icon {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #ff8100;
          margin-right: 8px;
          position: relative;
          top: 2px;
        }

        .btns {
          display: flex;
          margin-top: 32px;
        }

        .btns.is-odd {
          justify-content: flex-end;
        }

        .btns > :global(.btn-link):not(:last-child) {
          margin-right: 24px;
        }

        .image-container {
          margin-left: 32px;
          margin-right: 0;
          position: relative;
          z-index: 10;
        }

        .image-container.is-odd {
          margin-right: 32px;
          margin-left: 0;
        }

        .image-container :global(.gatsby-image-wrapper) {
          width: 420px;
        }

        .image-container.is-gif :global(.gatsby-image-wrapper) {
          width: 580px;
        }

        .image-container :global(.gatsby-image-wrapper) :global(img) {
          object-fit: contain !important;
          width: 100%;
        }

        @media (max-width: 1090px) {
          .content {
            margin-left: 32px;
          }

          .slide .is-odd {
            left: 0;
          }

          .content.is-odd {
            margin-left: 0;
            margin-right: 32px;
          }

          .image-container :global(.gatsby-image-wrapper) {
            width: 400px;
          }

          .image-container.is-gif :global(.gatsby-image-wrapper) {
            width: 500px;
          }
        }

        @media (max-width: 1000px) {
          .image-container :global(.gatsby-image-wrapper) {
            width: 300px;
          }

          .image-container.is-gif :global(.gatsby-image-wrapper) {
            width: 400px;
          }
        }

        @media (max-width: 930px) {
          h1 {
            font-size: 40px;
          }

          .btns {
            flex-direction: column;
          }

          .btns.is-odd {
            justify-content: unset;
            align-items: flex-end;
          }

          .btns > :global(.btn-link) {
            max-width: 300px;
            width: 100%;
          }

          .btns > :global(.btn-link):not(:last-child) {
            margin-right: 0;
            margin-bottom: 24px;
          }
        }

        @media (max-width: 850px) {
          h1 {
            font-size: 32px;
          }

          ul {
            font-size: 14px;
          }
        }

        @media (max-width: 790px) {
          .slide {
            flex-direction: column-reverse;
          }

          .slide .image-container.is-odd {
            order: 2;
          }

          .content {
            margin-left: 0;
          }

          .content.is-odd {
            margin-right: 0;
          }

          .image-container {
            margin-left: 0;
            margin-bottom: 32px;
          }

          .image-container.is-odd {
            margin-right: 0;
          }

          h1.is-odd {
            text-align: left;
          }

          .image-container :global(.gatsby-image-wrapper) {
            width: 200px;
          }

          .image-container.is-gif :global(.gatsby-image-wrapper) {
            width: 300px;
          }

          .btns {
            flex-direction: row;
            margin-bottom: 32px;
          }

          .btns > :global(.btn-link):not(:last-child) {
            margin-right: 24px;
            margin-bottom: 0;
          }
        }

        @media (max-width: 720px) {
          .slide {
            padding-left: 20px;
            padding-right: 20px;
          }
        }

        @media (max-width: 480px) {
          .btns > :global(.btn-link) {
            max-width: unset;
            font-size: 14px;
          }
        }

        @media (max-width: 395px) {
          ul {
            font-size: 12px;
          }

          h1 {
            font-size: 24px;
          }
        }
      `}</style>
    </div>
  );
}

function PrevArrow({ onClick }) {
  return (
    <button onClick={onClick}>
      <img src={leftArrow} alt="left-arrow" />
      <style jsx>{`
        button {
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
          z-index: 40;
          border: none;
          outline: none;
          background: transparent;
        }
        button:focus {
          outline: none;
        }
        img {
          width: 15px;
        }

        @media (max-width: 600px) {
          button {
            top: 150px;
          }
        }
      `}</style>
    </button>
  );
}

function NextArrow({ onClick }) {
  return (
    <button onClick={onClick}>
      <img src={rightArrow} alt="right-arrow" />
      <style jsx>{`
        button {
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translateY(-50%);
          z-index: 40;
          border: none;
          outline: none;
          background: transparent;
        }
        button:focus {
          outline: none;
        }
        img {
          width: 15px;
        }

        @media (max-width: 600px) {
          button {
            top: 150px;
          }
        }
      `}</style>
    </button>
  );
}
