import React from "react";
import { Link } from "gatsby";

import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { PostJsonLd } from "./JsonLd";

export default function Post({ post }) {
  return (
    <Link className="blog-post" key={post.id} to={post.fields.slug}>
      <PostJsonLd post={post.frontmatter} slug={post.fields.slug} />
      <article>
        <header>
          {post.frontmatter.featuredimage ? (
            <PreviewCompatibleImage
              imageInfo={{
                image: post.frontmatter.featuredimage,
                alt: `featured image thumbnail for post ${post.frontmatter.title}`
              }}
            />
          ) : null}
        </header>
        <p>
          {post.frontmatter.title}
          <br />
          <span>{post.frontmatter.date}</span>
        </p>
      </article>
      <style jsx>{`
        :global(a.blog-post) {
          display: block;
          width: calc(33.33% - 32px);
          margin: 16px;
          color: inherit;
          transition: all 0.25s;
        }
        header :global(img) {
          width: 100%;
        }
        p {
          font-family: "Open Sans", sans-serif;
          font-size: 18px;
        }
        p span {
          font-size: 14px;
          color: #888;
        }
        :global(a.blog-post):hover {
          transform: scale(1.05);
        }

        @media (max-width: 1080px) {
          :global(a.blog-post) {
            width: calc(50% - 32px);
          }
        }

        @media (max-width: 740px) {
          :global(a.blog-post) {
            width: 100%;
            margin: 16px 0;
          }
        }
      `}</style>
    </Link>
  );
}
