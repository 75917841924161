import React from "react";
import { BlogPostJsonLd } from "gatsby-plugin-next-seo";
import useSiteMetadata from "./SiteMetadata";
import { ProductJsonLd as ProductJson } from "gatsby-plugin-next-seo";
import { getProductPrice } from "../utils/index";
import { useMisc } from "../hooks/useMisc";

export function PostJsonLd({ post, slug }) {
  const { siteUrl } = useSiteMetadata();
  const {
    logos: { horizontalLogo }
  } = useMisc();
  const logoHorizontal = horizontalLogo.childImageSharp.fluid.src;
  return (
    <BlogPostJsonLd
      url={`${siteUrl}${slug}`}
      title={post.seo.title}
      name={post.seo.title}
      images={[`${siteUrl}${post.seo.image.publicURL}`]}
      datePublished={post.date}
      dateModified={post.dateIso}
      authorName={post.seo.authorName || post.authorName}
      description={post.seo.description}
      publisherLogo={"MycoForest"}
      publisherName={`${siteUrl}${logoHorizontal}`}
    />
  );
}

export function ProductJsonLd({ product }) {
  const { siteUrl } = useSiteMetadata();
  return (
    <ProductJson
      mpn={product.mpn}
      sku={product.mpn}
      productName={product.seo.title}
      images={[`${siteUrl}${product.seo.image.publicURL}`]}
      description={product.seo.description}
      brand={product.specifications.brand}
      aggregateRating={{
        ratingValue: product.rating,
        reviewCount: product.reviews
      }}
      reviews={product.reviewsList}
      offers={{
        price: getProductPrice(product.price, product.discount),
        priceCurrency: "INR",
        priceValidUntil: "2030-01-01",
        itemCondition: "NewCondition",
        availability: product.inStock ? "InStock" : "OutOfStock",
        url: `${siteUrl}${product.slug}`,
        seller: {
          name: product.specifications.brand
        }
      }}
    />
  );
}
